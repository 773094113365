import { useEffect, useState } from "react";
import { Space, Switch, Table } from "antd";
import { jobLists } from "../../../services/admin.service";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function JobLists() {
  const navigate = useNavigate();

  const [isListFetched, setListFetched] = useState(true);
  const [userLists, setUserLists] = useState([]);

  const [columns, setColumns] = useState([
    {
      title: "JobId",
      dataIndex: "jobId",
      key: "jobId",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "AuthorityEmail",
      dataIndex: "authorityEmail",
      key: "authorityEmail",
    },
    {
      title: "AuthorityName",
      dataIndex: "authorityName",
      key: "authorityName",
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "EmploymentType",
      dataIndex: "employmentType",
      key: "employmentType",
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => <Switch checked={true} />,
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              console.log("Recod", record);
              navigate(`/careers/job/${record.jobId}`);
            }}
          >
            <EyeOutlined />{" "}
          </a>
          {/* <a>
            <DeleteOutlined />
          </a> */}
        </Space>
      ),
    },
  ]);

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const results = await jobLists();

        let { status, data } = results.data;
        if (isListFetched && status === 200) {
          const updatedLists = data?.map((item) => ({
            jobId: item?.jobId,
            authorityEmail: item.authorityEmail,
            authorityName: item?.authorityName,
            category: item?.category,
            companyName: item?.companyName,
            employmentType: item?.employmentType,
            createdAt: new Date(item?.createdAt).toLocaleDateString(),
          }));

          setUserLists(updatedLists); // Directly update the state with the new data
        }
      } catch (error) {
        console.log("🚀 ~ fetchLists ~ error:", error);
        let { message } = error?.response?.data;
        console.error("Error fetching job lists:", message);
      }
    };

    fetchLists();

    // Cleanup function
    return () => {
      setListFetched(false);
    };
  }, []);
  return (
    <div className="mt-5">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h3
            className="text-secondary text text-light"
            style={{ fontSize: 25, fontWeight: "bold" }}
          >
            Job Lists
          </h3>
        </div>
      </div>
      <hr className="text-light" />
      <Table columns={columns} dataSource={userLists} />
    </div>
  );
}
