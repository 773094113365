import { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

export default function Home() {
  const [homeContent, setHomeContent] = useState([
    "Redefining industry standards with cutting-edge technology.",
    "Harnessing advanced algorithms for unparalleled performance.",
    "Setting new benchmarks for reliability and durability.",
    "Connecting businesses worldwide for international success.",
    "Anticipating needs and delivering tailor-made solutions.",
    "Comp01, where the world of 0s and 1s meets limitless possibilities.",
  ]);

  const [content, setContent] = useState(homeContent[0]);

  const updateDataContainer = (index) => {
    setContent(homeContent[index]);
  };

  useEffect(() => {
    let index = 0;
    setInterval(() => {
      updateDataContainer(index);
      index = (index + 1) % homeContent.length;
    }, 4000);
  }, []);

  return (
    <div className="site-content p-4">
      <Header />
      <div className="row text-center mt-1">
        <div className="col-md-12 col-sm-12">
          <h1 className="company-name">comp01</h1>
          <p className="home-content">
            Discover our latest innovations and technologies.
          </p>
        </div>
      </div>
      <div className="row text-center" style={{ marginTop: "12vw" }}>
        <div className="col-md-12 col-sm-12">
          <p className="data-container">{content}</p>
        </div>
      </div>
      <div className="row" style={{ marginTop: "280px" }}>
        <div className="col-md-12 col-sm-12">
          <Footer />
        </div>
      </div>
    </div>
  );
}
