import { Link } from "react-router-dom";

export default function Header() {
    return (
        <div className="row p-5">
            <div className="col-md-12 col-sm-12 text-end">
                <Link className="home-content" to="/career/jobs" style={{cursor: "pointer",color: "#ffffff",textDecoration: "none"}}>Career</Link>
            </div>
        </div>
    );
}