import { useEffect, useState } from "react";

import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";

export default function CareerCard({ loading, jobDetails, type }) {
  const navigate = useNavigate();

  const applyNowEvent = () => {
    navigate(`/career/job/register/${jobDetails.category}`);
  };
  return (
    <div>
      <Card
        loading={loading}
        style={{
          minWidth: "100%",
          fontFamily: "'Courier New', Courier, monospace",
        }}
      >
        {type === "career" ? (
          <Card.Meta
            onClick={() => navigate(`/career/job/${jobDetails._id}`)}
            title={<p className="text text-dark">{`${jobDetails.jobTitle}`}</p>}
            description={
              <div className="mt-2">
                <p className="text-dark mb-2">
                  Work Mode: {jobDetails?.workMode ?? "NA"}
                </p>
                <p className="text-dark mb-2">
                  Work Location: {jobDetails?.workLocation ?? "NA"}
                </p>
                <p className="text-dark mb-2">Job ID: {jobDetails.jobId}</p>
                <p className="text-dark mb-2">
                  Posted On:{" "}
                  {new Date(jobDetails.createdAt).toLocaleDateString()}
                </p>
              </div>
            }
          />
        ) : (
          <Card.Meta
            description={
              <div>
                <Button
                  label="Apply Now"
                  textColor="light"
                  backgroundColor="#000000"
                  borderRadius={40}
                  width={100}
                  padding={3}
                  clickEvent={applyNowEvent}
                />
                <div className="p-2">
                  <p className="text" style={{ color: "#000000" }}>
                    Employment Type:
                    <p className="text-secondary text p-1">
                      {jobDetails?.employmentType === "fullTime"
                        ? "Full Time"
                        : "NA" ?? "NA"}
                    </p>
                  </p>

                  <p className="text" style={{ color: "#000000" }}>
                    Category:
                    <p className="text-secondary text p-1">
                      {jobDetails?.category}
                    </p>
                  </p>

                  <p className="text" style={{ color: "#000000" }}>
                    Location:
                    <p className="text-secondary text p-1">
                      {jobDetails?.workLocation ?? "NA"}
                    </p>
                  </p>
                  <p className="text" style={{ color: "#000000" }}>
                    Work Mode:
                    <p className="text-secondary text p-1">
                      {jobDetails?.workMode ?? "NA"}
                    </p>
                  </p>
                </div>
              </div>
            }
          />
        )}
      </Card>
    </div>
  );
}
