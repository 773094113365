import { useState } from "react";

export default function Calendar({ handleInputs, name }) {
    
    const handleCalendar = (e) => {
        handleInputs(e);
    };
    
    
  return (
    <>
      <input className={`form-control form-control-md text`} id={name} type="date" onChange={handleCalendar}  max={new Date().toISOString().split("T")[0]} />
    </>
  );
}
