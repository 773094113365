import React, { useState } from "react";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { createJob } from "../../../services/admin.service";
import AlertView from "../../../components/alert/Alert";

export default function CreateJob() {
  const [showError, setShowError] = useState(false);
  const [job, setJob] = useState("");
  const [userCategory, setUserCategory] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [workMode, setWorkMode] = useState("");
  const [workLocation, setworkLocation] = useState("");
  const [responsibilities, setResponsibilities] = useState("");
  const [description, setDescription] = useState("");
  const [hiringProcess, setHiringProcess] = useState([{ value: "" }]);
  const [skill, setSkill] = useState([{ value: "" }]);
  const [authorityEmail, setAuthorityEmail] = useState("");
  const [authorityName, setAuthorityName] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertColor, setAlertColor] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const addSkillInput = (type) => {
    if (type === "hiring") {
      setHiringProcess((prev) => [...prev, { value: "" }]);
    } else {
      setSkill((prev) => [...prev, { value: "" }]);
    }
  };

  const removeSkillInput = (type, index) => {
    if (type === "hiring") {
      setHiringProcess((prev) => prev.filter((_, i) => i !== index));
    } else {
      setSkill((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleInputs = (e) => {
    if (e && e.target) {
      const { type, value, id } = e.target;
      switch (type) {
        case "text":
          if (id === "description") {
            setDescription(value);
          } else if (id === "jobTitle") {
            setJob(value);
          } else if (id === "skills") {
            setSkill(value);
          } else if (id === "workLocation") {
            setworkLocation(value);
          } else if (id === "authorityName") {
            setAuthorityName(value);
          }
          break;
        case "email":
          if (id === "authorityEmail") {
            setAuthorityEmail(value);
          }
          break;
        case "textarea":
          if (id === "responsibilities") {
            setResponsibilities(value);
          }
          else {
            setDescription(value);
          }
          break;
        case "radio":
          if (id === "category") {
            setUserCategory(value);
          } else if (id === "employmentType") {
            setEmploymentType(value);
          } else if (id === "workMode") {
            setWorkMode(value);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleSkillChange = (type, index, e) => {
    const { value } = e.target;
    if (type === "hiring") {
      const updatedHiringProcess = [...hiringProcess];
      updatedHiringProcess[index].value = value;
      setHiringProcess(updatedHiringProcess);
    } else {
      const updatedSkillProcess = [...skill];
      updatedSkillProcess[index].value = value;
      setSkill(updatedSkillProcess);
    }
  };

  const doFormSubmit = async () => {
    try {
      if (
        !job ||
        !skill ||
        !responsibilities ||
        !description ||
        !userCategory ||
        !employmentType ||
        !workMode ||
        !workLocation ||
        !hiringProcess ||
        !authorityEmail ||
        !authorityName
      ) {
        setShowError(true);
      } else {
        let payload = {
          authorityEmail: authorityEmail,
          authorityName: authorityName,
          category: userCategory,
          employmentType: employmentType,
          workMode: workMode,
          description: description,
          jobTitle: job,
          skills: skill.map((elem) => elem.value),
          hiringProcess: hiringProcess.map((elem) => elem.value),
          responsibilities: responsibilities,
        };

        if (workMode === "office") {
          payload["workLocation"] = workLocation;
        }

        let isCreated = await createJob(payload);
        let { data = [], status = 409, message } = isCreated?.data;
        if (status === 200) {
          setAlertMessage(message);
          setAlertColor("success");
          setOpenAlert(true);
          disableAlert();
          setDisableBtn(false);
        } else {
          setAlertMessage(message);
          setAlertColor("error");
          setOpenAlert(true);
          disableAlert();
          setDisableBtn(false);
        }
      }
    } catch (error) {
      let { message } = error?.response?.data;
      setAlertMessage(message);
      setAlertColor("error");
      setOpenAlert(true);
      disableAlert();
    }
  };

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const disableAlert = () => {
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  return (
    <form className="mt-5">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h3
            className="text-secondary text text-light"
            style={{ fontSize: 25, fontWeight: "bold" }}
          >
            Create Job
          </h3>
        </div>
        <div className="col-md-6 col-sm-12">
          <AlertView
            color={alertColor}
            message={alertMessage}
            openAlert={openAlert}
            onClose={closeAlert}
          />
        </div>
      </div>
      <hr className="text-light" />
      <div className="p-3">
        <div className="row">
          <div className="w-500">
            <label className="form-label text text-secondary">Category *</label>
            <div onChange={handleInputs}>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !userCategory ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="category"
                  id="category"
                  value="fresher"
                />
                <label
                  className="form-check-label text text-light"
                  for="category"
                >
                  Fresher
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !userCategory ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="category"
                  id="category"
                  value="experience"
                />
                <label
                  className="form-check-label text text-light"
                  for="category"
                >
                  Experience
                </label>
              </div>
            </div>
            <label
              className={`form-label text ${
                showError && !userCategory ? "error-text" : ""
              }`}
            >
              {showError && !userCategory ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label className="form-label text text-secondary">
              Employment type *
            </label>
            <div onChange={handleInputs}>
              <div class="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !employmentType ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="employmentType"
                  id="employmentType"
                  value="fullTime"
                />
                <label
                  class="form-check-label text text-light"
                  for="employmentType"
                >
                  Full time
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !employmentType ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="employmentType"
                  id="employmentType"
                  value="partTme"
                />
                <label
                  class="form-check-label text text-light"
                  for="employmentType"
                >
                  Part time
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !employmentType ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="employmentType"
                  id="employmentType"
                  value="intern"
                />
                <label
                  class="form-check-label text text-light"
                  for="employmentType"
                >
                  Intern
                </label>
              </div>
            </div>
            <label
              className={`form-label text ${
                showError && !employmentType ? "error-text" : ""
              }`}
            >
              {showError && !employmentType ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row">
          <div className="w-500">
            <label className="form-label text text-secondary">
              Work mode *
            </label>
            <div onChange={handleInputs}>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !workMode ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="workMode"
                  id="workMode"
                  value="remote"
                />
                <label className="form-check-label text text-light" for="remote">
                  Remote
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !workMode ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="workMode"
                  id="workMode"
                  value="office"
                />
                <label className="form-check-label text text-light" for="office">
                  Office
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input ${
                    showError && !workMode ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="workMode"
                  id="workMode"
                  value="hybrid"
                />
                <label className="form-check-label text text-light" for="hybrid">
                  Hybrid
                </label>
              </div>
            </div>
            <label
              className={`form-label text ${
                showError && !workMode ? "error-text" : ""
              }`}
            >
              {showError && !workMode ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        {workMode && workMode === "office" ? (
          <div className="row mt-2">
            <div className="w-50">
              <label
                htmlFor="jobTitle"
                className="form-label text text-secondary"
              >
                Work Location *
              </label>
              <input
                className={`form-control form-control-md text ${
                  showError && !workLocation ? "is-invalid" : ""
                }`}
                type="text"
                id="workLocation"
                name="workLocation"
                onChange={handleInputs}
              />
              <label
                className={`form-label text ${
                  showError && !workLocation ? "error-text" : ""
                }`}
              >
                {showError && !workLocation ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mt-2">
          <div className="w-50">
            <label
              htmlFor="jobTitle"
              className="form-label text text-secondary"
            >
              Job title/Position *
            </label>
            <input
              className={`form-control form-control-md text ${
                showError && !job ? "is-invalid" : ""
              }`}
              type="text"
              id="jobTitle"
              name="jobTitle"
              onChange={handleInputs}
            />
            <label
              className={`form-label text ${
                showError && !job ? "error-text" : ""
              }`}
            >
              {showError && !job ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label htmlFor="skills" className="form-label text text-secondary">
              Authority email *
            </label>
            <input
              className={`form-control form-control-md text ${
                showError && !authorityEmail ? "is-invalid" : ""
              }`}
              type="email"
              id="authorityEmail"
              name="authorityEmail"
              onChange={handleInputs}
            />
            <label
              className={`form-label text ${
                showError && !authorityEmail ? "error-text" : ""
              }`}
            >
              {showError && !authorityEmail ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label htmlFor="skills" className="form-label text text-secondary">
              Authority name *
            </label>
            <input
              className={`form-control form-control-md text ${
                showError && !authorityName ? "is-invalid" : ""
              }`}
              type="text"
              id="authorityName"
              name="authorityName"
              onChange={handleInputs}
            />
            <label
              className={`form-label text ${
                showError && !authorityName ? "error-text" : ""
              }`}
            >
              {showError && !authorityName ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label htmlFor="skills" className="form-label text text-secondary">
              Hiring process *
            </label>
            {hiringProcess?.length &&
              hiringProcess.map((elem, index) => {
                return (
                  <div>
                    <div key={index} className="d-flex align-items-center mb-2">
                      <input
                        className={`form-control form-control-md text me-2 ${
                          showError && !hiringProcess[index].value
                            ? "is-invalid"
                            : ""
                        }`}
                        type="text"
                        value={elem.value}
                        onChange={(e) => handleSkillChange("hiring", index, e)}
                      />

                      <PlusCircleOutlined
                        onClick={() => addSkillInput("hiring")}
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      />
                      {hiringProcess.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => removeSkillInput("hiring", index)}
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <label
                      className={`form-label text ${
                        showError && !hiringProcess[index].value
                          ? "error-text"
                          : ""
                      }`}
                    >
                      {showError && !hiringProcess[index].value
                        ? "This field is required !"
                        : ""}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label htmlFor="skills" className="form-label text text-secondary">
              Skills/Technologies *
            </label>
            {skill?.length &&
              skill.map((elem, index) => {
                return (
                  <div>
                    <div key={index} className="d-flex align-items-center mb-2">
                      <input
                        className={`form-control form-control-md text me-2 ${
                          showError && !skill[index].value ? "is-invalid" : ""
                        }`}
                        type="text"
                        value={elem.value}
                        onChange={(e) => handleSkillChange("skill", index, e)}
                      />

                      <PlusCircleOutlined
                        onClick={() => addSkillInput("skill")}
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      />
                      {skill.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => removeSkillInput("skill", index)}
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <label
                      className={`form-label text ${
                        showError && !skill[index].value ? "error-text" : ""
                      }`}
                    >
                      {showError && !skill[index].value
                        ? "This field is required !"
                        : ""}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label
              htmlFor="responsibilities"
              className="form-label text text-secondary"
            >
              Responsibilities *
            </label>
            <textarea
              rows={5}
              className={`form-control form-control-md text ${
                showError && !responsibilities ? "is-invalid" : ""
              }`}
              type="text"
              id="responsibilities"
              onChange={handleInputs}
            />
            <label
              className={`form-label text ${
                showError && !responsibilities ? "error-text" : ""
              }`}
            >
              {showError && !responsibilities ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="w-50">
            <label
              htmlFor="jobTitle"
              className="form-label text text-secondary"
            >
              Description *
            </label>
            <textarea
              rows={5}
              className={`form-control form-control-md text ${
                showError && !description ? "is-invalid" : ""
              }`}
              type="text"
              id="description"
              onChange={handleInputs}
            />
            <label
              className={`form-label text ${
                showError && !description ? "error-text" : ""
              }`}
            >
              {showError && !description ? "This field is required !" : ""}
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-3">
            <button
              type="button"
              className="btn btn-light text w-100"
              style={{ backgroundColor: "#bdbdbd" }}
            >
              Cancel
            </button>
          </div>

          <div className="col-md-3">
            <button
              type="button"
              className="btn btn-dark text w-100"
              onClick={doFormSubmit}
              disabled={disableBtn}
            >
              submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
