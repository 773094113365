import { Navigate, Outlet, useNavigate } from "react-router-dom";
import NavBar from "../navBar/NavBar";
import SideBar from "../sidebar/Sidebar";

export default function ProtectedRoutes() {
  const sessionKey = true;
  return (
    <div>
      {sessionKey ? (
        <div className="container-fluid mt-2">
          <NavBar />
          <div className="row">
            <div className="col-md-2 col-sm-12 mt-3 fixed-sidebar">
              <SideBar />
            </div>
            <div className="col-md-10 col-sm-12 text-start content-area">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
}
