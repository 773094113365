import { useState } from "react";
import Calendar from "../../components/calendar/Calendar";

export default function FresherForm({ handleInputs, showError }) {
  const [college, setCollege] = useState("");
  const [department, setDepartment] = useState("");

  const handleInputFields = (e) => {
    const { type, value, id } = e.target;
    if (id === "college") {
      setCollege(value);
    } else if (id === "department") {
      setDepartment(value);
    }

    handleInputs(e);
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                College/University *
              </label>
              <input
                className={`form-control form-control-md text ${
                  showError && !college ? "is-invalid" : ""
                }`}
                type="text"
                id="college"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !college ? "error-text" : ""
                }`}
              >
                {showError && !college ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">Department *</label>
              <input
                className={`form-control form-control-md text ${
                  showError && !department ? "is-invalid" : ""
                }`}
                type="text"
                id="department"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !department ? "error-text" : ""
                }`}
              >
                {showError && !department ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Studying From *
              </label>
              <Calendar handleInputs={handleInputFields} name={"studyingFrom"} />
              <label></label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Studying To *
              </label>
              <Calendar handleInputs={handleInputFields} name={"studyingTo"} />
              <label></label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
