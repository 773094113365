import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import ProtectedRoutes from './components/routes/ProtectedRoutes';
import Login from './pages/authenticate/Login';
import Dashboard from './pages/dashboard/Dashboard';
import CreateJob from './pages/admin/job/CreateJob';
import JobLists from './pages/admin/job/JobLists';
import Home from './pages/home/Home';
import CareerHome from './pages/careers/careerHome';
import CareerDetails from './pages/careers/careerDetails';
import CareerRegister from './pages/careers/Register';

function App() {
  const sessionKey = true; // Replace this with actual session logic

  return (
    <div className="App">
      <Routes>
        {!sessionKey && <Route path='/login' element={<Login />} />}

        <Route path='/' element={<Home />} />

        <Route path='/career'>
          <Route path='jobs' element={<CareerHome />} />
          <Route path='job/:jobId' element={<CareerDetails />} />
          <Route path='job/register/:type' element={<CareerRegister />} />
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route path='/login' element={<Navigate to="/" />} />

          <Route path='/dashboard' element={<Dashboard />} />

          <Route path='/careers/job'>
            <Route path='create' element={<CreateJob />} />
            <Route path='lists' element={<JobLists />} />
            {/* <Route path=':jobId' element={<JobDetails />} /> */}
          </Route>
          
        </Route>
      </Routes>
    </div>
  );
}

export default App;
