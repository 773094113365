import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

import ExperienceForm from "./ExperienceForm";
import FresherForm from "./FresherForm";
import AlertView from "../../components/alert/Alert";
import { userRegister } from "../../services/admin.service";
import { useParams } from "react-router-dom";

export default function CareerRegister() {
  const { type } = useParams();

  const [showError, setShowError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  // const [userType, setUserType] = useState("");
  const [department, setDepartment] = useState("");
  const [college, setCollege] = useState("");
  const [technologies, setTechnologies] = useState(null);
  const [gitHubUrl, setGitHubUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [studyingFrom, setStudyingFrom] = useState(null);
  const [studyingTo, setStudyingTo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertColor, setAlertColor] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [company, setCompany] = useState(null);
  const [designation, setDesignation] = useState("");
  const [responsibility, setResponsibility] = useState("");
  const [currentCTC, setCurrentCTC] = useState("");
  const [expectedCTC, setExpectedCTC] = useState("");

  const handleInputs = (e) => {
    if (e && e?.target) {
      const { type, value, id } = e.target;
      console.log("🚀 ~ handleInputs ~ value, id:", type, value, id);
      switch (type) {
        case "text":
          if (id === "name") {
            setName(value);
          } else if (id === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(value)) {
              setEmail(value);
              setEmailError(false); // Clear error when valid email
            } else {
              setEmail(""); // Invalid email format
              setEmailError(true); // Show error message
            }
          } else if (id === "department") {
            setDepartment(value);
          } else if (id === "technologies") {
            setTechnologies(value);
          } else if (id === "college") {
            setCollege(value);
          } else if (id === "github") {
            setGitHubUrl(value);
          } else if (id === "linkedIn") {
            setLinkedInUrl(value);
          } else if (id === "company") {
            setCompany(value);
          } else if (id === "designation") {
            setDesignation(value);
          }
          break;
        case "textarea":
          if (id === "responsibility") {
            setResponsibility(value);
          }
          break;
        case "number":
          if (id === "currentCTC") {
            setCurrentCTC(value);
          } else if (id === "expectedCTC") {
            setExpectedCTC(value);
          }
          break;
        case "date":
          if (id === "studyingFrom") {
            setStudyingFrom(value);
          } else if (id === "studyingTo") {
            setStudyingTo(value);
          } else if (id === "startDate") {
            setStartDate(value);
          } else if (id === "endDate") {
            setEndDate(value);
          }
          break;
        default:
          break;
      }
    } else {
      // Phone number input handling
      e = String(e);
      const phoneNumber = parsePhoneNumber(e);
      if (
        phoneNumber &&
        phoneNumber.country === "IN" &&
        isPossiblePhoneNumber(e)
      ) {
        setMobile(e);
        setPhoneError(false);
      } else {
        setMobile(""); // Invalid phone number
        setPhoneError(true); // Show an error if it's not a valid Indian number
      }
    }
  };

  const disableAlert = () => {
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  const doRegister = async () => {
    try {
      let payload = {
        email: email,
        name: name,
        category: type,
        mobileNumber: mobile,
        technologies: technologies,
        gitHubUrl: gitHubUrl,
        linkedInUrl: linkedInUrl,
      };

      if (type === "fresher") {
        if (
          !name ||
          !email ||
          !mobile ||
          !department ||
          !college ||
          !technologies
        ) {
          setShowError(true);
        } else {
          setDisableBtn(true);
          payload = {
            ...payload,
            collegeName: college,
            department: department,
            studyingFrom: studyingFrom,
            studyingTo: studyingTo,
          };
        }
      } else {
        if (
          !name ||
          !email ||
          !mobile ||
          !technologies ||
          !company ||
          !designation ||
          !responsibility ||
          !currentCTC ||
          !expectedCTC
        ) {
          setShowError(true);
        } else {
          setDisableBtn(true);
          payload = {
            ...payload,
            companyName: company,
            designation: designation,
            responsibility: responsibility,
            currentCTC: currentCTC,
            expectedCTC: expectedCTC,
            startDate: startDate,
            endDate: endDate,
          };

          let isCreated = await userRegister(payload);
          let { data = [], status = 409, message } = isCreated?.data;
          if (status === 200) {
            setAlertMessage(message);
            setAlertColor("success");
            setOpenAlert(true);
            disableAlert();
            setDisableBtn(false);
          } else {
            setAlertMessage(message);
            setAlertColor("error");
            setOpenAlert(true);
            disableAlert();
            setDisableBtn(false);
          }
        }
      }
    } catch (error) {
      let { message } = error?.response?.data;
      setAlertMessage(message);
      setAlertColor("error");
      setOpenAlert(true);
    }
  };

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const props = {
    name: "file",
    action: "http://localhost:3002/api/v1/user/upload/resume",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF files!");
      }
      return isPdf || Upload.LIST_IGNORE;
    },
    onChange(info) {
      console.log("🚀 ~ onChange ~ info:", info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="mt-5">
      <p className="h4 title text-light text-center text p-3">APPLY FOR JOB</p>
      <div className="rounded mt-2">
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <AlertView
              color={alertColor}
              message={alertMessage}
              openAlert={openAlert}
              onClose={closeAlert}
            />
          </div>
        </div>
        <form>
          <div className="row mt-2">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">Name *</label>
                <input
                  className={`form-control form-control-md text ${
                    showError && !name ? "is-invalid" : ""
                  }`}
                  type="text"
                  id="name"
                  onChange={handleInputs}
                />
                <label
                  className={`form-label text ${
                    showError && !name ? "error-text" : ""
                  }`}
                >
                  {showError && !name ? "This field is required !" : ""}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">Email *</label>
                <input
                  className={`form-control form-control-md text ${
                    showError && !email ? "is-invalid" : ""
                  }`}
                  type="text"
                  id="email"
                  onChange={handleInputs}
                />
                <label
                  className={`form-label text ${
                    showError && !email
                      ? "error-text"
                      : emailError
                      ? "error-text"
                      : ""
                  }`}
                >
                  {showError && !email
                    ? "This field is required !"
                    : emailError
                    ? "Enter valid email !"
                    : ""}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">Mobile *</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={mobile}
                  onChange={handleInputs}
                  defaultCountry="IN"
                  international
                  limitMaxLength
                />
                <label
                  className={`form-label text ${
                    showError && !mobile
                      ? "error-text"
                      : phoneError
                      ? "error-text"
                      : ""
                  }`}
                >
                  {showError && !mobile
                    ? "This field is required !"
                    : phoneError
                    ? "Enter valid phone number"
                    : ""}
                </label>
              </div>
            </div>
          </div>

          {type === "fresher" ? (
            <FresherForm handleInputs={handleInputs} showError={showError} />
          ) : (
            <ExperienceForm handleInputs={handleInputs} showError={showError} />
          )}

          <div className="row">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">
                  Github (optional) *
                </label>
                <input
                  className={`form-control form-control-md text`}
                  type="text"
                  id="github"
                  onChange={handleInputs}
                />
                <label></label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">
                  LinkedIn (optional) *
                </label>
                <input
                  className={`form-control form-control-md text`}
                  type="text"
                  id="linkedIn"
                  onChange={handleInputs}
                />
                <label></label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">
                  Technologies *
                </label>
                <input
                  className={`form-control form-control-md text ${
                    showError && !technologies ? "is-invalid" : ""
                  }`}
                  type="text"
                  id="technologies"
                  onChange={handleInputs}
                />
                <label
                  className={`form-label text ${
                    showError && !technologies ? "error-text" : ""
                  }`}
                >
                  {showError && !technologies ? "This field is required !" : ""}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-6 col-sm-12">
              <div className="w-100">
                <label className="form-label text text-light">
                  Upload Resume *
                </label>
                <br />
                <Upload {...props} accept=".pdf">
                  <Button icon={<UploadOutlined color="white" />}>
                    Click to Upload{" "}
                  </Button>
                </Upload>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-3 col-sm-12 p-2">
              <button type="button" className="btn btn-secondary w-100 text">
                cancel
              </button>
            </div>
            <div className="col-md-3 col-sm-12 p-2">
              <button
                type="button"
                className="btn btn-light w-100 text"
                onClick={doRegister}
                disabled={disableBtn}
              >
                save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
