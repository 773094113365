import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchJobDetail } from "../../services/admin.service";


import Button from "../../components/button/Button";
import CareerCard from "../../components/card/careerCard";

export default function CareerDetails() {
  const { jobId } = useParams();

  const [loading, setLoading] = useState(true);
  const [isJobFetched, setJobFetched] = useState(true);
  const [jobDetails, setJobDetails] = useState("");

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const results = await fetchJobDetail(jobId);

        let { status, data } = results.data;
        console.log("🚀 ~ fetchLists ~ data:", data);
        if (isJobFetched && status === 200) {
          setJobDetails(data);
          setLoading(false);
        }
      } catch (error) {
        console.log("🚀 ~ fetchLists ~ error:", error);
        let { message } = error?.response?.data;
        console.error("Error fetching job lists:", message);
      }
    };

    fetchJobDetails();

    return () => {
      setJobFetched(false);
    };
  }, [jobId]);

  return (
    <div className="p-5" style={{backgroundColor: "#000000"}}>
      <div className="row mt-5">
        <div className="col-md-2"></div>
        <div className="col-md-6">
          <p className="title">{jobDetails.jobTitle}</p>
          <p
            className="mt-5 text-light text"
            style={{ lineHeight: 2.2 }}
          > { jobDetails.description}</p>

          <div className="mt-5">
            <h4 className="sub-title">Key responsibilities</h4>
            <p className="text text-light mt-3">
              {jobDetails.responsibilities}
            </p>
          </div>

          <div className="mt-5">
            <h4 className="sub-title">Skills, knowledge & expertise</h4>
            {jobDetails.skills?.length
              ? jobDetails.skills.map((item) => {
                  return (
                    <p className="text text-light mt-3">
                      <li>{item}</li>
                    </p>
                  );
                })
              : ""}
          </div>

          <div className="mt-5 p-5 text-center">
            <Button
              label="Apply Now"
              textColor="dark"
              backgroundColor="#ffffff"
              borderRadius={40}
              width={50}
              padding={3}
            />
          </div>
          <hr style={{color: "#ffffff"}} />

          <div className="mt-5">
            <h4 className="sub-title text-center">Our Hiring Process</h4>
            <div className="d-flex justify-content-around p-3 mt-5">
              {jobDetails.hiringProcess?.length
                ? jobDetails.hiringProcess.map((responsibilities,index) => {
                    return (
                        <p className="sub-title" style={{color:"#ffffff"}}>{`stage ${index + 1} :`} <br />
                        <p className="text text-light mt-2">
                        {responsibilities}
                      </p></p>
                      
                    );
                  })
                : ""}
            </div>
          </div>
        </div>

        <div className="col-md-3 p-5 vh-100">
          <CareerCard loading={loading} jobDetails={jobDetails} type="careerJob" />
        </div>
      </div>
    </div>
  );
}
