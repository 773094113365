import axios from "axios";
import EndPoint from "../utils/endpoint";

export const createJob = async (payload) => {
    const url = EndPoint.baseUrl + "/api/v1/admin/job"
    return await axios.post(url, payload);
};

export const jobLists = async () => {
    const url = EndPoint.baseUrl + "/api/v1/admin/job/lists"
    return await axios.get(url);
};

export const fetchJobDetail = async (jobId) => {
    const url = EndPoint.baseUrl + `/api/v1/admin/job/${jobId}`
    return await axios.get(url);
};

export const userRegister = async (payload) => {
    console.log("🚀 ~ userRegister ~ payload:", payload)
    const url = EndPoint.baseUrl + "/api/v1/user/register"
    return await axios.post(url, payload);
}
