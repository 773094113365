import { useEffect, useState } from "react";

import { jobLists } from "../../services/admin.service";
import { useNavigate } from "react-router-dom";
import CareerCard from "../../components/card/careerCard";

export default function JobOpenings() {
  const navigate = useNavigate();
  const [isListFetched, setListFetched] = useState(true);
  const [jobData, setJobData] = useState([""]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const results = await jobLists();

        let { status, data } = results.data;
        if (isListFetched && status === 200) {
          setJobData(data);
          setLoading(false);
        }
      } catch (error) {
        let { message } = error?.response?.data;
      }
    };

    fetchLists();

    // Cleanup function
    return () => {
      setListFetched(false);
    };
  }, []);

  return (
    <div
      className="container-fluid p-5 site-content"
      style={{ height: "100vh" }}
    >
      <div className="row">
        {jobData?.length &&
          jobData.map((item) => {
            return (
              <div className="col-md-3 col-sm-12 p-2" key={item.jobId}>
                <CareerCard loading={loading} jobDetails={item} type="career" />
              </div>
            );
          })}
      </div>
    </div>
  );
}
