import Alert from "@mui/material/Alert";

export default function AlertView({
  color = "success",
  message,
  openAlert,
  onClose,
}) {
  return (
    <>
      {openAlert && (
        <Alert variant="outlined" severity={color} onClose={onClose}>
          {message}
        </Alert>
      )}
    </>
  );
}
