import { useState } from "react";
import Calendar from "../../components/calendar/Calendar";

export default function ExperienceForm({ handleInputs, showError }) {
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [responsibility, setResponsibility] = useState("");
  const [currentCTC, setCurrentCTC] = useState("");
  const [expectedCTC, setExpectedCTC] = useState("");

  const handleInputFields = (e) => {
    const { type, value, id } = e.target;
    if (id === "company") {
      setCompany(value);
    } else if (id === "designation") {
      setDesignation(value);
    } else if (id === "responsibility") {
      setResponsibility(value);
    } else if (id === "currentCTC") {
      setCurrentCTC(value);
    } else if (id === "expectedCTC") {
      setExpectedCTC(value);
    }

    handleInputs(e);
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Company name *
              </label>
              <input
                className={`form-control form-control-md text ${
                  showError && !company ? "is-invalid" : ""
                }`}
                type="text"
                id="company"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !company ? "error-text" : ""
                }`}
              >
                {showError && !company ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Designation *
              </label>
              <input
                className={`form-control form-control-md text ${
                  showError && !designation ? "is-invalid" : ""
                }`}
                type="text"
                id="designation"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !designation ? "error-text" : ""
                }`}
              >
                {showError && !designation ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Current CTC *
              </label>
              <input
                className={`form-control form-control-md text ${
                  showError && !currentCTC ? "is-invalid" : ""
                }`}
                type="number"
                id="currentCTC"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !currentCTC ? "error-text" : ""
                }`}
              >
                {showError && !currentCTC ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Expected CTC *
              </label>
              <input
                className={`form-control form-control-md text ${
                  showError && !expectedCTC ? "is-invalid" : ""
                }`}
                type="number"
                id="expectedCTC"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !expectedCTC ? "error-text" : ""
                }`}
              >
                {showError && !expectedCTC ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">
                Responsibility *
              </label>
              <textarea
                rows={5}
                className={`form-control form-control-md text ${
                  showError && !responsibility ? "is-invalid" : ""
                }`}
                type="text"
                id="responsibility"
                onChange={handleInputFields}
              />
              <label
                className={`form-label text ${
                  showError && !responsibility ? "error-text" : ""
                }`}
              >
                {showError && !responsibility ? "This field is required !" : ""}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">Start date *</label>
              <Calendar handleInputs={handleInputFields} name={"startDate"} />
              <label></label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="w-100">
              <label className="form-label text text-light">End date *</label>
              <Calendar handleInputs={handleInputFields} name={"endDate"} />
              <label></label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
