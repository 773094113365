export default function Button({
  label,
  textColor = "light",
  backgroundColor = "#8a4af3",
  borderRadius = 0,
  width = 100,
  padding = 3,
  clickEvent,
}) {
  return (
    <button
      type="button"
      className={`btn w-${width} text-${textColor}  text mb-3 p-${padding}`}
      style={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        fontFamily: "'Courier New', Courier, monospace",
        fontWeight: "bold",
      }}
      onClick={clickEvent}
    >
      {label}
    </button>
  );
}
