import { styled } from "@mui/material";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";

export default function NavBar() {
  const navigate = useNavigate();
  const MenuItems = styled(MenuItem)({
    mr: 2,
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#333333",
    textDecoration: "none",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const viewVehicles = (event) => {
    console.log("event", event.target.outerText);
    const type = event.target.outerText;
    setAnchorEl(null);
    navigate(`/vehicles/${type}`);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        color: "#333333",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            fontFamily: "'Courier New', Courier, monospace",
            fontWeight: 700,
            // letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          COMP01
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
          <Avatar alt="Gowtham sankar" src="/static/images/avatar/1.jpg" />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
