import { AppstoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SideBar() {
  const navigate = useNavigate();
  const items = [
    {
      key: "sub1",
      label: "Jobs",
      icon: <AppstoreOutlined />,
      children: [
        {
          key: "create_job",
          label: "create job",
        },
        {
          key: "view_job",
          label: "view job",
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "sub2",
      label: "Settings",
      icon: <SettingOutlined />,
    },
    {
      type: "divider",
    },
  ];
  const onClick = (e) => {
    switch (e.key) {
      case "create_job":
        navigate("/careers/job/create");
        break;
        case "view_job":
          navigate("/careers/job/lists");
          break;
      default:
        break;
    }
  };
  return (
    <div className="sideBar rounded text text-dark border h-100 mt-2">
      <Menu
        className="title"
      onClick={onClick}
      style={{
        width: "100%",
        fontSize: 12,
        border: "none"
      }}
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      mode="inline"
      items={items}
    />
    </div>
  );
}
